<template>
  <div v-if="role" class="add-new-role">
    <title-bar
        submitBtn
        :submitBtnTitle="$t('messages.roleUpdate')"
        :title="$t('messages.roleUpdate')"
        @submitPressed="submitPressed"
    />
    <b-card no-body>
      <b-tabs v-model="tabIndex" card content-class="mt-3">
        <b-tab :title="$t('forms.overview')" active>
          <role-form
              :roleObject="role"
              :action="action"
              @clearAction="action = null"
          />
        </b-tab>
        <b-tab title="Employees">
          <users :roleObject="role" type="employees"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import RoleForm from "./components/RoleForm.vue";
import Users from "./components/Users.vue";

export default {
  components: {
    TitleBar,
    RoleForm,
    Users
  },
  created() {
    this.load();
  },
  data() {
    return {
      action: null,
      role: null,
    };
  },
  methods: {
    load() {
      const id = this.$route.params.id;
      this.$Roles.getResource({id}).then((response) => {
        this.role = response.data;

        this.$Enums.getCollection({params: {pagination: false, cache: true, ttl: 86400}})
            .then(response => {
              response.data['hydra:member'].forEach(element => {
                if (element.name === 'USER_ACCESS_PERMISSION') {
                  element.keys.forEach(key => {
                    if (-1 === this.role.permissions.findIndex(permission => permission.name === key)) {
                      this.role.permissions.push({
                        name: key,
                        LIST: false,
                        LIST_ALL: false,
                        VIEW: false,
                        CREATE: false,
                        UPDATE: false,
                        DELETE: false,
                      })
                    }
                  })
                }
                if (element.name === 'ADDITIONAL_USER_ACCESS_PERMISSION') {
                  element.keys.forEach(key => {
                    this.role.additionalPermissions.push({
                      name: key,
                      value: false,
                    })
                  })
                }
              })
            })
      });
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
