<template>
  <div>
    <div v-if="rows" class="custom-table">
      <table-actions
          :actions="['search', 'perPage']"
          @onSearchInput="inputChange"
          @perPageChange="perPageChange"
          :defaultPerPage="perPage"
      />
      <table-lite
          :is-slot-mode="true"
          :is-loading="isLoading"
          :columns="columns"
          :rows="rows"
          :sortable="sortable"
          @do-search="setSort"
          @is-finished="isLoading = false"
      >
        <template v-slot:name="data">
          <span v-if="type === 'customers'">
            {{ data.value.customer }}
          </span>
          <span v-if="type === 'employees'">
            {{ data.value.givenName }} {{ data.value.familyName }}
          </span>
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <delete-btn @pressDelete="deleteUser(data.value['@id'])"/>
          </div>
        </template>
      </table-lite>
      <table-pagination
          v-model="currentPage"
          :total="pages"
          :per-page="perPage"
          @input="onChangePage"
      />
    </div>
    <b-card :header="'Add '+type" header-tag="header" class="roles-add-user">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col lg="6">
            <b-form-group
                class="row"
                label="User"
                label-for="user"
                label-cols-md="4"
                content-cols-lg="8"
            >
              <v-select id="user" v-model="userSelect" :options="userList"/>
              <div
                  class="input-errors"
                  v-for="error of v$.userSelect.$errors"
                  :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>
            <b-button type="submit" variant="primary">Add</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import vSelect from "vue-select";
import ListUtils from "@/mixins/ListUtils";

export default {
  name: "AdminTravellingListing",
  components: {
    TableLite,
    TablePagination,
    TableActions,
    DeleteBtn,
    vSelect,
  },
  props: {
    roleObject: {Object, required: true},
    type: {type: String, default: "employees"},
  },
  mixins: [ResourceUtils, ListUtils],
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      role: this.roleObject,
      search: "",
      isLoading: false,
      userSelect: null,
      rows: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      sortable: {
        order: "date",
        sort: "asc",
      },
      userList: [],
      columns: [
        {
          label: this.$t("forms.name"),
          field: "name",
          sortable: true,
        },
        {
          label: this.$t("forms.actions"),
          field: "actions",
          sortable: false,
          width: "80px",
        },
      ],
    };
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      userSelect: {required},
    };
  },
  created() {
    const roleName = this.type === "employees" ? "ROLE_EMPLOYEE" : "ROLE_CUSTOMER";
    this.userList = [];
    this.getUserList({params: {pagination: false, role: roleName}}, this.userList);
    this.load();
  },
  methods: {
    load() {
      const roleOrder = this.type === "employees" ? "familyName" : "customer";
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
      };
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      params["order[" + roleOrder + "]"] = this.sortable.sort;
      this.$Roles
          .getResourceByUrl({url: "/roles/" + this.role.id + "/" + this.type, params}, "user_list")
          .then((response) => {
            this.rows = response.data["hydra:member"];
          });
    },
    updateRole() {
      const users = [];
      if (this.userSelect) {
        this.rows.push({
          "@id": this.userSelect.id,
        });
      }
      this.rows.forEach((element) => {
        users.push(element["@id"]);
      });
      let body = {
        'employees': users
      }
      if (this.type === 'customers') {
        body = {
          'customers': users
        }
      }
      this.update(
          this.$Roles,
          this.role.id,
          body,
          this.$t("messages.roleUpdated"),
          null,
          this.success,
          this.error
      );
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        this.updateRole()
      } else {
        this.$emit("clearAction");
      }
    },
    setSort(offset, limit, order, sort) {
      this.sortable.sort = sort;
      this.sortable.order = order;
      this.load();
    },
    onChangePage(page) {
      this.currentPage = page;
      this.load();
    },
    deleteUser(url) {
      for (let i = 0; i < this.rows.length; i++) {
        if (this.rows[i]['@id'] === url) {
          this.rows.splice(i, 1)
        }
      }
      this.updateRole()
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.load();
    },
    onPageChange() {
      this.load();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.load();
    },
    success() {
      this.load()
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

